export default function Footer() {
  return (
    <footer className=" bg-[#00005E]">
        <div className="py-8">
            <img
                className="w-[121px] lg:w-[100px] mx-auto"
                src="/images/subastop.co-logo.png"
                alt="Logo"
            />
        </div>
        <div className="grid grid-cols-3">
            <div className="grid col-span-2 gap-2 pl-8 text-white text-[13px] leading-[21px]">
                <p className="text-[16px] font-semibold leading-[26px]">Nuestros Brands</p>
                <a href="https://www.vmcsubastas.com/" target="_blank" rel="noreferrer">VMC Subastas</a>
                <a href="https://blacksheepnation.org/" target="_blank" rel="noreferrer">BlackSheep Nation</a>
                <p>SubasCars - Próximamente</p>

                <div className="grid grid-cols-4 mt-5">
                    <a href="https://www.facebook.com/vmcsubastas" target="_blank" rel="noreferrer">
                        <img className="max-w-[28px]" src="/images/footer-icon-facebook.png" alt="Footer Icon Facebook" />
                    </a>
                    <a href="https://www.linkedin.com/company/subastopco/" target="_blank" rel="noreferrer">
                        <img className="max-w-[28px]" src="/images/footer-icon-linkedin.png" alt="Footer Icon LinkedIn" />
                    </a>
                    <a href="https://www.instagram.com/vmcsubastas/" target="_blank" rel="noreferrer">
                        <img className="max-w-[28px]" src="/images/footer-icon-instagram.png" alt="Footer Icon Instagram" />
                    </a>
                </div>
            </div>
            <div>
                <img className="max-w-[60px] mx-auto" src="/images/in-positive-web-logo.png" alt="In+ Challenge Logo" />
            </div>
        </div>
        <hr className="w-[328px] lg:w-[406px] h-px mx-auto my-6 lg:mt-[13px] lg:mb-[25px] bg-[#F0F0F4] border-0 rounded" />
        <div className="pb-8 text-center text-white text-[13px] leading-[21px]">
            <p>© 2023 Subastop Co.</p>
            <p>Todos los derechos reservados</p>
        </div>
    </footer>
  )
}
