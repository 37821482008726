export default function Navbar() {
  return (
    <nav className="fixed top-0 left-0 right-0 bg-[#00005E] z-30">
        <div className="px-5 mx-auto">
        <div className="flex justify-between h-16">
            <div className="flex">
            <div className="flex items-center shrink-0">
                <a href="/">
                <img
                    className="w-[81px] lg:w-[100px]"
                    src="/images/subastop.co-logo.png"
                    alt="Logo"
                />
                </a>
            </div>
            </div>
            <div className="flex items-center gap-2 lg:gap-7">
                <img src="/images/user-icon.svg" alt="4 Panel" />
            </div>
        </div>
        </div>
    </nav>
  )
}